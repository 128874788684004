import ProductList from "../productList/ProductList";

function App() {
	return (
		<div className="App">
			<ProductList />
		</div>
	);
}

export default App;
